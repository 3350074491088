import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import reportWebVitals from "../../../reportWebVitals";
import { useTranslation } from "react-i18next";

const getTrackerId = () => {
  let trackingId = "FG08RX0SK8"; //PROD

  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("dev.")
  ) {
    trackingId = "W9YHSG7G0B"; //DEV
  } else if (window.location.href.includes("pat.")) {
    trackingId = "Z659NFRYG4"; //PAT
  }

  return trackingId;
};

const getOneTrustId = () => {
  let oneTrustId = "23b66028-5b83-4480-8e7f-0e4f77c54f34";
  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("dev.") ||
    window.location.href.includes("pat.")
  ) {
    oneTrustId += "-test";
  }

  return oneTrustId;
};

//returns .td.com or .tdsecurities.com or .tdbank.com or localhost
const extractDomain = (hostname: string) => {
  let domain: string = "";

  if (hostname.includes("localhost")) {
    domain = "localhost";
  } else {
    const parts: string[] = hostname.split(".");

    if (parts.length > 1) {
      domain = "." + parts[parts.length - 2] + "." + parts[parts.length - 1];
    }
  }

  return domain;
};

const useOneTrust = (showLogin: boolean) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [cookies, , removeCookie] = useCookies();

  const ONE_TRUST_COOKIE_NAME = "OptanonConsent";
  const googleTrackedCookieName = "_ga_" + getTrackerId();
  const ONE_TRUST_TRACKING_DISABLED = "C0004:0";
  const ONE_TRUST_TRACKING_ENABLED = "C0004:1";

  useEffect(() => {
    if (showLogin) {
      if (document.documentElement.lang !== i18n.language.substring(0, 2)) {
        //set index.html lang
        document.documentElement.lang = i18n.language.substring(0, 2);
        // @ts-ignore
        if (window.OneTrust) {
          // @ts-ignore
          window.OneTrust.changeLanguage();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (showLogin) {
      let oneTrustId = getOneTrustId();

      const head = document.querySelector("head");
      const script = document.createElement("script");

      script.setAttribute(
        "src",
        `https://cdn.cookielaw.org/consent/${oneTrustId}/otSDKStub.js`
      );
      script.setAttribute("data-document-language", "true");
      script.setAttribute("charSet", "UTF-8");
      script.setAttribute("data-domain-script", `${oneTrustId}`);

      //add OneTrust script
      head!.appendChild(script);

      return () => {
        head!.removeChild(script);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (showLogin) {
      const cookieValue = cookies[ONE_TRUST_COOKIE_NAME];

      //if cookie has COO4 flag set to 0 and Google Analytics tracking cookie exists
      if (
        cookieValue &&
        cookies[ONE_TRUST_COOKIE_NAME].indexOf(ONE_TRUST_TRACKING_DISABLED) >
          0 &&
        cookies[googleTrackedCookieName]
      ) {
        const domain = extractDomain(window.location.hostname);
        const attributes = { path: "/", domain: domain };

        removeCookie(googleTrackedCookieName, attributes);
        removeCookie("_ga", attributes);
        removeCookie("_gat", attributes);
        removeCookie("_gid", attributes);

        window.location.reload();
      }

      //if cookie has COO4 flag set to 1
      if (
        cookieValue &&
        cookies[ONE_TRUST_COOKIE_NAME].indexOf(ONE_TRUST_TRACKING_ENABLED) > 0
      ) {
        ReactGA.initialize("G-" + getTrackerId(), {
          gaOptions: {
            //debug_mode: true,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, removeCookie, googleTrackedCookieName]);

  React.useEffect(() => {
    if (showLogin) {
      const cookieValue = cookies[ONE_TRUST_COOKIE_NAME];

      if (
        cookieValue &&
        cookies[ONE_TRUST_COOKIE_NAME].indexOf(ONE_TRUST_TRACKING_ENABLED) > 0
      ) {
        //no cookie or COO4 flag is not 0
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname + location.search,
        });

        //https://github.com/react-ga/react-ga/issues/504
        reportWebVitals(({ id, name, value, delta }) =>
          ReactGA.event({
            action: name,
            category: "Web Vitals",
            label: id,
            nonInteraction: true,
            value: delta,
            //value: Math.round(name === "CLS" ? value * 1000 : value)
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, cookies]);
};
export default useOneTrust;
