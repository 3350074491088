import React, {useEffect} from "react"

export interface ChatBotProps {
}

const ChatBot: React.FC<ChatBotProps> = () => {
    useEffect(() => {
        if (
            //only for localhost and dev env.
            (
                window.location.href.includes("localhost") ||
                window.location.href.includes("dev.")
            )
        ) {
            const script = document.createElement("script");

            script.text =
                "  window.watsonAssistantChatOptions = {\n" +
                "    integrationID: \"229d0be9-5f0b-4144-aeff-a96f5dbd5229\", // The ID of this integration.\n" +
                "    region: \"us-south\", // The region your integration is hosted in.\n" +
                "    serviceInstanceID: \"ff02dcab-4bd8-4636-bb6a-6e8fdcc04516\", // The ID of your service instance.\n" +
                "    subscriptionID: \"4603450e052e49898b89718da3251ac5\", // Your plan subscription ID.\n" +
                "    onLoad: function(instance) { instance.render(); }\n" +
                "  };\n" +
                "  setTimeout(function(){\n" +
                "    const t=document.createElement('script');\n" +
                "    t.src=\"https://web-chat.global.assistant.watson.appdomain.cloud/versions/\" + (window.watsonAssistantChatOptions.clientVersion || 'latest') + \"/WatsonAssistantChatEntry.js\";\n" +
                "    document.head.appendChild(t);\n" +
                "  })";

            document.body.appendChild(script);

            return () => {
                // clean up the script when the component in unmounted
                document.body.removeChild(script)
            }
        }
    }, [])

    return <></>
}

export default ChatBot