import * as React from "react";
import { createHttpClient, createSecureHttpClient } from "../utils/HttpClient";
import { useTranslation } from "react-i18next";
import { ApplicationTransferType } from "../constants/types";
import { useCookies } from "react-cookie";
import uuid from "react-uuid";
import { SESSION_ID_COOKIE_NAME } from "../constants/constants";
import { useNavigate } from "react-router-dom";
import {
  ERROR,
  ERROR_NOT_ACTIVE,
  ERROR_NOT_AUTHORIZED,
} from "../constants/routePaths";

interface IAuthContext {
  getToken: () => string | null;
  authenticated: boolean;
  authenticate: () => void;
  authenticateForSaml: (samlRequest: string) => void;
  stepUpAuthenticate: (nonce: string) => void;
  error: string | null;
  callback: (code: string | null) => void;
  callbackApp: (appId: string, appCallbackUrl: string) => void;
  callbackAppForStepUp: (
    code: string | null,
    appId: string,
    appStepUpCallbackUrl: string,
    nonce: string | null
  ) => void;
  callbackAppWithSamlAssertion: (
    samlRequest: string,
    samlRelayState: string
  ) => void;
  logout: () => void;
  logoutApp: (appId: string, appCallbackUrl: string) => void;
  transferToUAPM: () => void;
}

interface ISecurityConfig {
  uapmTransferUrl: string;
  uapmRedirectUrl: string;
  uapmChannelId: string;
  uapmTsnConsumerAppId: string;
  applicationTransferTypes: IApplicationTransferTypes;
  applicationLogoutTypes: IApplicationLogoutTypes;
  applicationLogoutUrls: IApplicationLogoutUrls;
}

interface IApplicationTransferTypes {
  [key: string]: string;
}

interface IApplicationLogoutTypes {
  [key: string]: string;
}

interface IApplicationLogoutUrls {
  [key: string]: string[];
}

interface IAccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string | null;
}

interface ISamlAuthResponse {
  spPostUrl: string;
  samlResponse: string;
}

interface IOAuthSAMLResponse {
  base64EncodedSAMLAssertion: string;
}

const TOKEN_KEY = "token";
const EXPIRY_KEY = "expiry";

export const APP_ID_KEY = "appId";
export const APP_CALLBACK_URL_KEY = "appCallbackUrl";
export const APP_LOGOUT_URL_KEY = "appLogoutUrl";
export const APP_NONCE_KEY = "nonce";
export const APP_STEP_UP_CALLBACK_URL_KEY = "appStepUpCallbackUrl";
export const SRC = "src";
export const SRC_URP = "URP";

//saml related
export const SAML_REQUEST_KEY = "SAMLRequest";
export const SAML_RELAYSTATE_KEY = "RelayState";

const AuthContext = React.createContext<IAuthContext>(null!);

interface IAuthProviderProps {}

const getToken = () => localStorage.getItem(TOKEN_KEY);

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  let [securityConfig, setSecurityConfig] = React.useState<ISecurityConfig>(
    null!
  );
  let [authenticated, setAuthenticated] = React.useState<boolean>(() => {
    const token = getToken();
    if (token) {
      const expiry = Number(localStorage.getItem(EXPIRY_KEY));
      if (Date.now() > expiry) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(EXPIRY_KEY);
        return false;
      }
      return true;
    }
    return false;
  });
  let [error, setError] = React.useState<string | null>(null);

  const { i18n } = useTranslation();

  const [, setCookie, removeCookie] = useCookies([SESSION_ID_COOKIE_NAME]);

  React.useEffect(() => {
    createHttpClient()
      .get<ISecurityConfig>("/security/config")
      .then((resp) => {
        const config = resp.data;
        setSecurityConfig(config);
      });
  }, []);

  let clearAuthStatus = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(EXPIRY_KEY);
    removeCookie(SESSION_ID_COOKIE_NAME);
    setError(null);
    setAuthenticated(false);
  };

  let language = () => (i18n.language.startsWith("en") ? "en_CA" : "fr_CA");

  let authenticate = () => {
    const token = getToken();
    if (token) {
      window.location.href = "/";
      return;
    }

    clearAuthStatus();

    console.log("redirecting to pingfed...");
    window.location.href = "/security/uap-redirect?lng=" + language();
  };

  let authenticateForSaml = (samlRequest: string) => {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", "/security/saml/authenticate");

    const languageInput = document.createElement("input");
    languageInput.setAttribute("type", "hidden");
    languageInput.setAttribute("name", "lng");
    languageInput.setAttribute("value", language());
    form.appendChild(languageInput);

    const samlRequestInput = document.createElement("input");
    samlRequestInput.setAttribute("type", "hidden");
    samlRequestInput.setAttribute("name", "samlRequest");
    samlRequestInput.setAttribute("value", samlRequest);
    form.appendChild(samlRequestInput);

    document.body.appendChild(form);
    form.submit();
  };

  let stepUpAuthenticate = (nonce: string) => {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", "/security/uap-step-up-redirect");

    const languageInput = document.createElement("input");
    languageInput.setAttribute("type", "hidden");
    languageInput.setAttribute("name", "lng");
    languageInput.setAttribute("value", language());
    form.appendChild(languageInput);

    const nonceInput = document.createElement("input");
    nonceInput.setAttribute("type", "hidden");
    nonceInput.setAttribute("name", "nonce");
    nonceInput.setAttribute("value", nonce);
    form.appendChild(nonceInput);

    const tokenInput = document.createElement("input");
    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "token");
    tokenInput.setAttribute("value", getToken()!);
    form.appendChild(tokenInput);

    document.body.appendChild(form);
    form.submit();
  };

  let callback = (code: string | null) => {
    if (code) {
      createHttpClient()
        .post<IAccessTokenResponse>("/security/token", {
          code,
        })
        .then((resp) => {
          const token = resp.data.access_token;
          localStorage.setItem(TOKEN_KEY, token);
          localStorage.setItem(
            EXPIRY_KEY,
            (Date.now() + resp.data.expires_in * 1000).toString()
          );
          setCookie(SESSION_ID_COOKIE_NAME, uuid());
          setAuthenticated(true);
          setError(null);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            navigate(ERROR_NOT_ACTIVE, { replace: true });
          } else {
            console.log("Error when getting access token", err);
            setError(err.response.data?.error);
          }
        });
    } else {
      setError("No code received");
    }
  };

  let callbackAppWithSamlAssertion = (
    samlRequest: string,
    samlRelayState: string
  ) => {
    createHttpClient()
      .post<ISamlAuthResponse>("/security/saml/generate/response", {
        samlRequest,
        token: getToken()!,
      })
      .then((resp) => {
        const spPostUrl = resp.data.spPostUrl;
        const samlResponse = resp.data.samlResponse;

        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", spPostUrl);

        const samlResponseInput = document.createElement("input");
        samlResponseInput.setAttribute("type", "hidden");
        samlResponseInput.setAttribute("name", "SAMLResponse");
        samlResponseInput.setAttribute("value", samlResponse);
        form.appendChild(samlResponseInput);

        const relayStateInput = document.createElement("input");
        relayStateInput.setAttribute("type", "hidden");
        relayStateInput.setAttribute("name", "RelayState");
        relayStateInput.setAttribute("value", samlRelayState);
        form.appendChild(relayStateInput);

        document.body.appendChild(form);
        form.submit();
      })
      .catch((err) => {
        navigate(ERROR + "?message=unable to get saml assertion", {
          replace: true,
        });
      });
  };

  let callbackApp = (appId: string, appCallbackUrl: string) => {
    if (!securityConfig.applicationTransferTypes[appId]) {
      setError(`Unrecognized app ${appId}`);
      return;
    }

    let appType = securityConfig.applicationTransferTypes[appId];
    if (ApplicationTransferType.JWT === appType) {
      const form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", "/security/app-auth-code");

      const accessTokenInput = document.createElement("input");
      accessTokenInput.setAttribute("type", "hidden");
      accessTokenInput.setAttribute("name", "accessToken");
      accessTokenInput.setAttribute("value", getToken()!);
      form.appendChild(accessTokenInput);

      const appIdInput = document.createElement("input");
      appIdInput.setAttribute("type", "hidden");
      appIdInput.setAttribute("name", "appId");
      appIdInput.setAttribute("value", appId);
      form.appendChild(appIdInput);

      const appCallbackUrlInput = document.createElement("input");
      appCallbackUrlInput.setAttribute("type", "hidden");
      appCallbackUrlInput.setAttribute("name", "appCallbackUrl");
      appCallbackUrlInput.setAttribute("value", appCallbackUrl);
      form.appendChild(appCallbackUrlInput);

      const lng = document.createElement("input");
      lng.setAttribute("type", "hidden");
      lng.setAttribute("name", "lng");
      lng.setAttribute("value", language());
      form.appendChild(lng);

      document.body.appendChild(form);
      form.submit();
    } else if (ApplicationTransferType.SAML === appType) {
      callbackAppWithSaml(appId, appCallbackUrl);
    }
  };

  let callbackAppForStepUp = (
    code: string | null,
    appId: string,
    appStepUpCallbackUrl: string,
    nonce: string | null
  ) => {
    if (code) {
      const form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", "/security/step-up-app-auth-code");

      const codeInput = document.createElement("input");
      codeInput.setAttribute("type", "hidden");
      codeInput.setAttribute("name", "code");
      codeInput.setAttribute("value", code!);
      form.appendChild(codeInput);

      const accessTokenInput = document.createElement("input");
      accessTokenInput.setAttribute("type", "hidden");
      accessTokenInput.setAttribute("name", "accessToken");
      accessTokenInput.setAttribute("value", getToken()!);
      form.appendChild(accessTokenInput);

      const appIdInput = document.createElement("input");
      appIdInput.setAttribute("type", "hidden");
      appIdInput.setAttribute("name", "appId");
      appIdInput.setAttribute("value", appId);
      form.appendChild(appIdInput);

      const appStepUpCallbackUrlInput = document.createElement("input");
      appStepUpCallbackUrlInput.setAttribute("type", "hidden");
      appStepUpCallbackUrlInput.setAttribute("name", "appStepUpCallbackUrl");
      appStepUpCallbackUrlInput.setAttribute("value", appStepUpCallbackUrl);
      form.appendChild(appStepUpCallbackUrlInput);

      const nonceInput = document.createElement("input");
      nonceInput.setAttribute("type", "hidden");
      nonceInput.setAttribute("name", "nonce");
      nonceInput.setAttribute("value", nonce!);
      form.appendChild(nonceInput);

      document.body.appendChild(form);
      form.submit();
    } else {
      setError("No code received");
    }
  };

  let callbackAppWithSaml = (appId: string, appCallbackUrl: string) => {
    createSecureHttpClient(getToken()!)
      .post<IOAuthSAMLResponse>("/security/saml-token", {
        appId: appId,
        appCallbackUrl: appCallbackUrl,
        token: getToken(),
      })
      .then((response) => {
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", appCallbackUrl);

        const samlToken = document.createElement("input");
        samlToken.setAttribute("type", "hidden");
        samlToken.setAttribute("name", "SAMLResponse");
        samlToken.setAttribute(
          "value",
          response.data.base64EncodedSAMLAssertion
        );
        form.appendChild(samlToken);

        document.body.appendChild(form);
        form.submit();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate(ERROR_NOT_ACTIVE, { replace: true });
        } else if (err.response.status === 403) {
          navigate(ERROR_NOT_AUTHORIZED, { replace: true });
        } else {
          console.log("Error when getting saml token", err);
          setError(err.response.data?.error);
        }
      });
  };

  let notifyLogout = () => {
    createHttpClient().post<IAccessTokenResponse>(
      "/security/logout-notification",
      {
        token: getToken(),
      }
    );
  };

  let logout = () => {
    notifyLogout();
    clearAuthStatus();
  };

  let logoutApp = (appId: string, appLogoutUrl: string) => {
    notifyLogout();
    clearAuthStatus();

    const logoutType = securityConfig.applicationLogoutTypes[appId];
    if (!logoutType) {
      setError(`Unrecognized app ${appId}`);
      return;
    }

    const logoutUrls = securityConfig.applicationLogoutUrls[appId];
    if (logoutUrls && logoutUrls.length > 0) {
      const logoutUrl = logoutUrls.find((url) => url === appLogoutUrl);
      if (!logoutUrl) {
        setError(`No matching logout url found`);
        return;
      } else {
        if (logoutType === "POST") {
          const form = document.createElement("form");
          form.setAttribute("method", "post");
          form.setAttribute("action", logoutUrl);

          const logout = document.createElement("input");
          logout.setAttribute("type", "hidden");
          logout.setAttribute("name", "logout");
          logout.setAttribute("value", "logout");
          form.appendChild(logout);

          document.body.appendChild(form);
          form.submit();
        } else {
          window.location.href = logoutUrl;
          return;
        }
      }
    } else {
      setError(`No matching logout url found`);
      return;
    }
  };

  let transferToUAPM = () => {
    const lng = language();

    createSecureHttpClient(getToken()!)
      .post<IOAuthSAMLResponse>("/security/saml-uapm")
      .then((resp) => {
        const samlToken = resp.data.base64EncodedSAMLAssertion;

        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", securityConfig.uapmTransferUrl);

        const returnURL = document.createElement("input");
        returnURL.setAttribute("type", "hidden");
        returnURL.setAttribute("name", "returnURL");
        returnURL.setAttribute("value", securityConfig.uapmRedirectUrl);
        form.appendChild(returnURL);

        const ssoType = document.createElement("input");
        ssoType.setAttribute("type", "hidden");
        ssoType.setAttribute("name", "ssoType");
        ssoType.setAttribute("value", "oauth");
        form.appendChild(ssoType);

        const ssoToken = document.createElement("input");
        ssoToken.setAttribute("type", "hidden");
        ssoToken.setAttribute("name", "ssoToken");
        ssoToken.setAttribute("value", samlToken);
        form.appendChild(ssoToken);

        const channelID = document.createElement("input");
        channelID.setAttribute("type", "hidden");
        channelID.setAttribute("name", "channelID");
        channelID.setAttribute("value", securityConfig.uapmChannelId);
        form.appendChild(channelID);

        const ln = document.createElement("input");
        ln.setAttribute("type", "hidden");
        ln.setAttribute("name", "LN");
        ln.setAttribute("value", lng);
        form.appendChild(ln);

        const tsnConsumerAppId = document.createElement("input");
        tsnConsumerAppId.setAttribute("type", "hidden");
        tsnConsumerAppId.setAttribute("name", "tsnConsumerAppId");
        tsnConsumerAppId.setAttribute(
          "value",
          securityConfig.uapmTsnConsumerAppId
        );
        form.appendChild(tsnConsumerAppId);

        document.body.appendChild(form);
        form.submit();
      })
      .catch((err) => {
        console.log("Error when getting saml token for UAPM", err);
        setError(err.response.data?.error);
      });
  };

  let value: IAuthContext = {
    getToken,
    authenticated,
    authenticate,
    authenticateForSaml,
    stepUpAuthenticate,
    error,
    callback,
    callbackApp,
    callbackAppForStepUp,
    callbackAppWithSamlAssertion,
    logout,
    logoutApp,
    transferToUAPM,
  };

  if (!securityConfig) {
    return <></>;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
