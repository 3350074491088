import React from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Callback from "./pages/Callback";
import NotFound from "./pages/NotFound";
import { AuthProvider } from "./auth/AuthProvider";
import RequireAuth from "./auth/RequireAuth";
import "./services/i18n";
import {
  CALLBACK,
  CONTACT_US,
  ERROR,
  ERROR_NOT_ACTIVE,
  ERROR_NOT_AUTHORIZED,
  LANDING_CONTACT_US,
  LANDING_LEARN,
  LEARN,
  LOGIN,
  LOGOUT,
  REGISTRATION,
  REQUEST_ACCESS_CONFIRMATION,
  ROOT,
  SHORT_REGISTRATION,
  UAP_REDIRECT,
  VERIFY_EMAIL,
} from "./constants/routePaths";
import MainContent from "./components/HomePage/MainContent/MainContent";
import Logout from "./pages/Logout";
import LearnMore from "./components/HomePage/LearnMore/LearnMore";
import Landing from "./pages/Landing";
import RegistrationRequest from "./components/HomePage/RegistrationRequest/RegistrationRequest";
import RequestAccessConfirmation from "./components/HomePage/RegistrationRequest/RequestAccessConfirmation";
import LandingMainContent from "./components/HomePage/MainContent/LandingMainContent";
import NotActive from "./pages/errors/NotActive";
import NotAuthorized from "./pages/errors/NotAuthorized";
import GlobalError from "./pages/GlobalError";
import ContactUs from "./components/HomePage/ContactUs/ContactUs";
import ShortRegistrationRequest from "./components/HomePage/RegistrationRequest/ShortRegistrationRequest";
import VerifyEmail from "./pages/VerifyEmail";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path={ROOT}
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        >
          <Route index element={<MainContent />} />
          <Route path={LEARN} element={<LearnMore />} />
          <Route path={CONTACT_US} element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={LOGIN} element={<Landing />}>
          <Route index element={<LandingMainContent />} />
        </Route>

        <Route path={LANDING_LEARN} element={<Landing />}>
          <Route index element={<LearnMore />} />
        </Route>

        <Route path={LANDING_CONTACT_US} element={<Landing />}>
          <Route index element={<ContactUs />} />
        </Route>

        <Route path={CALLBACK} element={<Callback />} />
        <Route path={REGISTRATION} element={<RegistrationRequest />} />

        <Route path={`${SHORT_REGISTRATION}/:app`} element={<Landing />}>
          <Route index element={<ShortRegistrationRequest />} />
        </Route>

        <Route
          path={REQUEST_ACCESS_CONFIRMATION}
          element={<RequestAccessConfirmation />}
        />

        <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />

        <Route path={LOGOUT} element={<Logout />} />
        {/* the below applies in localhost:3000, in localhost:8080 or dev/pat/prd
        it will get intercepted by spring boot controller with the same path
        /security/uap-redirect */}
        <Route path={UAP_REDIRECT} element={<NotFound />} />
        <Route path={ERROR_NOT_ACTIVE} element={<NotActive />} />
        <Route path={ERROR_NOT_AUTHORIZED} element={<NotAuthorized />} />
        <Route path={ERROR} element={<GlobalError />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
