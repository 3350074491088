import React, { createContext, FC, useState } from "react";
import Footer from "../components/HomePage/Footer/Footer";
import HeaderNavUnAuthenticated from "../components/HomePage/HeaderNav/HeaderNavUnAuthenticated";
import "./Landing.scss";
import {
  APP_CALLBACK_URL_KEY,
  APP_ID_KEY,
  APP_NONCE_KEY,
  APP_STEP_UP_CALLBACK_URL_KEY,
  SAML_RELAYSTATE_KEY,
  SAML_REQUEST_KEY,
  SRC,
  SRC_URP,
  useAuth,
} from "../auth/AuthProvider";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { HomePageData } from "../data/apiData";
import { LANDING_PAGE_PUBLIC_DATA_URL } from "../constants/urls";
import { createHttpClient } from "../utils/HttpClient";
import ReactGA from "react-ga4";
import useOneTrust from "../components/Common/OneTrust/OneTrust";

interface LandingProps {}

export const homePagePublicContext = createContext<HomePageData>(null!);

const Landing: FC<LandingProps> = () => {
  let {
    authenticated,
    authenticate,
    authenticateForSaml,
    stepUpAuthenticate,
    callbackApp,
    callbackAppWithSamlAssertion,
  } = useAuth();
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const [homePageData, setHomePageData] = useState<HomePageData>();

  React.useEffect(() => {
    const appId = searchParams.get(APP_ID_KEY);
    const appCallbackUrl = searchParams.get(APP_CALLBACK_URL_KEY);
    const appStepUpCallbackUrl = searchParams.get(APP_STEP_UP_CALLBACK_URL_KEY);
    const nonce = searchParams.get(APP_NONCE_KEY);

    //saml auth request params
    const samlRequest = searchParams.get(SAML_REQUEST_KEY);
    const samlRelayState = searchParams.get(SAML_RELAYSTATE_KEY);

    if (appId && appStepUpCallbackUrl && nonce) {
      sessionStorage.setItem(APP_ID_KEY, appId);
      sessionStorage.setItem(
        APP_STEP_UP_CALLBACK_URL_KEY,
        appStepUpCallbackUrl
      );
      sessionStorage.setItem(APP_NONCE_KEY, nonce);
      stepUpAuthenticate(nonce);
    } else {
      sessionStorage.removeItem(APP_STEP_UP_CALLBACK_URL_KEY);
      sessionStorage.removeItem(APP_NONCE_KEY);

      if (authenticated) {
        if (appId && appCallbackUrl) {
          callbackApp(appId, appCallbackUrl);
        } else if (samlRequest && samlRelayState) {
          callbackAppWithSamlAssertion(samlRequest, samlRelayState);
        } else {
          navigate("/", { replace: true });
        }
      } else {
        const src = searchParams.get(SRC);
        if (appId && appCallbackUrl) {
          sessionStorage.removeItem(SAML_REQUEST_KEY);
          sessionStorage.removeItem(SAML_RELAYSTATE_KEY);

          sessionStorage.setItem(APP_ID_KEY, appId);
          sessionStorage.setItem(APP_CALLBACK_URL_KEY, appCallbackUrl);
          authenticate();
        } else if (src && src === SRC_URP) {
          sessionStorage.removeItem(APP_ID_KEY);
          sessionStorage.removeItem(APP_CALLBACK_URL_KEY);
          sessionStorage.removeItem(SAML_REQUEST_KEY);
          sessionStorage.removeItem(SAML_RELAYSTATE_KEY);
          authenticate();
        } else if (samlRequest && samlRelayState) {
          sessionStorage.removeItem(APP_ID_KEY);
          sessionStorage.removeItem(APP_CALLBACK_URL_KEY);

          sessionStorage.setItem(SAML_REQUEST_KEY, samlRequest);
          sessionStorage.setItem(SAML_RELAYSTATE_KEY, samlRelayState);
          authenticateForSaml(samlRequest);
        } else {
          sessionStorage.removeItem(APP_ID_KEY);
          sessionStorage.removeItem(APP_CALLBACK_URL_KEY);
          sessionStorage.removeItem(SAML_REQUEST_KEY);
          sessionStorage.removeItem(SAML_RELAYSTATE_KEY);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  React.useEffect(() => {
    const fetchData = async () => {
      ReactGA.set({ userId: null });

      try {
        const response = await createHttpClient().get<HomePageData>(
          LANDING_PAGE_PUBLIC_DATA_URL
        );
        setHomePageData(response.data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const showLogin = () => {
    const appId = searchParams.get(APP_ID_KEY);
    const src = searchParams.get(SRC);
    const samlRequest = searchParams.get(SAML_RELAYSTATE_KEY);
    return (
      !authenticated && !appId && (!src || src !== SRC_URP) && !samlRequest
    );
  };

  useOneTrust(showLogin());

  return (
    <React.Fragment>
      {showLogin() && homePageData ? (
        <homePagePublicContext.Provider value={homePageData}>
          <div className="tds-app">
            <HeaderNavUnAuthenticated />
            <Outlet />
            <Footer />

            {/*<OneTrust/>*/}
          </div>
        </homePagePublicContext.Provider>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default Landing;
